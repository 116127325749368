export default [
  {
    title: 'homepage',
    icon: '',
    route: 'home',
  },
  {
    header: 'products',
    icon: '',
    children: [
      {
        title: 'Card',
        icon: 'CreditCardIcon',
        route: 'card-detail',
      },
      {
        title: 'PhoneCard',
        icon: 'CircleIcon',
        route: 'phone-card-detail',
      },
      {
        title: 'Tag',
        icon: 'CircleIcon',
        route: 'tag-detail',
      },
    ],
  },
  {
    title: 'contact',
    icon: '',
    route: 'contacts',
  },
]
